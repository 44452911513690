import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store'
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
// import 'element-plus/theme-chalk/index.css';

import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import './less/variable.less'
import { createStore } from "@/vuex/store";
import { applyTokenByCode, invitationCode, getAllModel, allPluginsList, myRole } from "@/api/api";
import { registerSockets, destroySockets, registerWebSockets, destroyWebSockets } from '@/utils/socket'
import { appUrl } from '@/utils/url'
import echarts from "./utils/echarts";
import { isToken, authIsShow } from "@/utils/utils";
import { truncateString } from "@/utils/utils";
import { websocketUrl } from '@/utils/url'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import VueSocketIO from 'vue-socket.io'
import 'vue3-video-play/dist/style.css' // 引入css
import { GlobalCmComponent } from "codemirror-editor-vue3";


import 'codemirror/lib/codemirror.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const store = createStore();
if (localStorage.getItem("userList")) {
  store.action.repeatUserList(
    JSON.parse(localStorage.getItem("userList") || "")
  );
}


if (localStorage.getItem("allPluginUser")) {
  store.action.repeatAllPlugin(
    JSON.parse(localStorage.getItem("allPluginUser") || "")
  );
}
// setTimeout(() => {
//   store.action.clearToken()
// }, 1000)
if (
  localStorage.getItem("nowSelect") &&
  localStorage.getItem("nowSelect") !== "undefined"
) {
  store.action.repeatNowSelect(
    JSON.parse(localStorage.getItem("nowSelect") || "")
  );
}

if (localStorage.getItem("chatData")) {
  store.action.repeatChatData(
    JSON.parse(localStorage.getItem("chatData") || "")
  );
}

getAllModel().then((ret) => {
  store.action.updataAllModel(ret.data)
});

allPluginsList().then((res) => {
  store.action.updataAllPlugin(res.data)
})
if (localStorage.getItem("token")) {
  const msg = {
    pageNo: 1,
    pageSize: 100,
  };
  myRole(msg).then((res: any) => {
    if (res.success) {
      store.action.getApplyList(res.data.list)
    }
  });
  store.action.setToken(localStorage.getItem("token") || "");
  if (!localStorage.getItem('shareId')) {
    invitationCode().then((red: any) => {
      if (red.success) {
        localStorage.setItem('shareId', red.data);
      }
    });
  }
}

if (localStorage.getItem("user")) {
  store.action.updateUser(JSON.parse(localStorage.getItem("user") || ""));
}


function isMobile() {
  // var isMobile = window.navigator.userAgent.match(
  //   /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  // ) // 是否手机端
  // var isWx = /micromessenger/i.test(navigator.userAgent) // 是否微信
  // var isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
  // if (isComWx && isMobile) {
  //   //手机端企业微信
  //   return 'com-wx-mobile'
  // } else if (isComWx && !isMobile) {
  //   //PC端企业微信
  //   return 'com-wx-pc'
  // } else if (isWx && isMobile) {
  //   // 手机端微信
  //   store.action.upDataIsWexin(true);
  // } else if (isWx && !isMobile) {
  //   // PC端微信
  //   return 'wx-pc'
  // } else {
  //   return 'other'
  // }
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobile = regex.test(navigator.userAgent);

  const isWxin = navigator.userAgent.toLowerCase();
  // 判断是否是微信环境
  const isWeixin = isWxin.indexOf("micromessenger") != -1;
  const isComWx = /wxwork/i.test(navigator.userAgent) // 是否企业微信
  // 判断是否是微信桌面端
  const isWeChatDesktop = /MicroMessenger/.test(navigator.userAgent) && !/Mobile/.test(navigator.userAgent);

  if (isWeixin && !isComWx) {
    store.action.upDataIsWexin(true);
  }
  if (isMobile || (isWeixin && !isWeChatDesktop)) {
    store.action.checkPhone(true);
  }
  if (isWeChatDesktop) {
    store.action.checkWechatDesktop(true)
  }

}

isMobile();

import "element-plus/dist/index.css";
// import { setupI18n } from './locales'
const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(vue3videoPlay)
app.use(router);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(GlobalCmComponent);
app.use(ElementPlus).mount("#app");
app.use(Viewer, {
  Options: {
    'inline': true,
    'button': true, //右上角按钮
    "navbar": false, //底部缩略图
    "title": true, //当前图片标题
    "toolbar": false, //底部工具栏
    "tooltip": true, //显示缩放百分比
    "movable": true, //是否可以移动
    "zoomable": true, //是否可以缩放
    "rotatable": false, //是否可旋转
    "scalable": false, //是否可翻转
    "transition": true, //使用 CSS3 过度
    "fullscreen": true, //播放时是否全屏
    "keyboard": true, //是否支持键盘
    "url": "data-source",
  }
});
const localUrl = window.location.href;
const hrefParams: Record<string, string> = {};
const queryParamsIndex = localUrl.indexOf("?");

if (queryParamsIndex !== -1) {
  const params = localUrl.substring(queryParamsIndex + 1);
  const arr = params.split("&");
  const arrs: string[] = [];

  for (let j = 0; j < arr.length; j++) {
    arrs.push(...arr[j].split("="));
  }

  for (let i = 0; i < arrs.length; i += 2) {
    hrefParams[arrs[i]] = arrs[i + 1];
  }
}
if (document.referrer && !localStorage.getItem("sourceDetail")) {
  localStorage.setItem("sourceDetail", document.referrer);
}
if (hrefParams.ref) {
  localStorage.setItem("source", hrefParams.ref);
}
if (hrefParams.share) {
  localStorage.setItem("shareCode", hrefParams.share);
}
const voice = hrefParams.voice == 'true' ? true : false
if (voice) {
  store.action.checkVoice(voice);
} else {
  store.action.checkVoice(false);
}

if (hrefParams.inviteParam) {
  // router.replace({
  //   path: '/console/factory',
  // });
  window.location.replace(`${appUrl}/console/factory`)
  localStorage.setItem('inviteParam', hrefParams.inviteParam)
  // store.action.updataInviteParam(hrefParams.inviteParam)
}
if (hrefParams.code && !localStorage.getItem("token")) {
  let shareCode = "";
  let msg: any = {};
  if (localStorage.getItem("shareCode")) {
    shareCode = localStorage.getItem("shareCode") || "";
    msg = {
      code: hrefParams.code,
      share: shareCode,
    };
  } else {
    msg = {
      code: hrefParams.code,
    };
  }
  if (localStorage.getItem('source')) {
    const source = localStorage.getItem('source') || '';
    msg['source'] = source;
  }
  if (localStorage.getItem('sourceDetail')) {
    const sourceDetail = localStorage.getItem('sourceDetail') || '';
    msg['sourceDetail'] = sourceDetail;
  }
  applyTokenByCode(msg).then((res: any) => {
    if (res.success) {
      store.action.setToken(res.data.token);
      store.action.updateUser(res.data.user);
      invitationCode().then((red: any) => {
        if (red.success) {
          localStorage.setItem('shareId', red.data);
        }
      });
      if (localStorage.getItem("shareCode")) {
        localStorage.removeItem("shareCode");
      }
      router.go(0);
    }
  });
}
// if (localStorage.getItem("token")) {
//   getUseMemberPrivige().then((res: any) => {
//     if (res.success) {
//       store.action.updataUserPrivige(res.data)
//       const menuList = [
//         {
//           name: '创造',
//           menu: [
//             { name: '应用', routerName: 'myApp' },
//             { name: '知识库', routerName: 'knowledgeBase' },
//             { name: '插件', routerName: 'plugins' },
//             { name: '工作流', routerName: 'flow' },
//           ],
//         },
//         {
//           name: '接入',
//           menu: [{ name: '应用接入', routerName: 'openInterface' }],
//         },
//         {
//           name: '管理',
//           menu: [
//             // { name: '返回首页', routerName: 'home' },
//             { name: '我的账户', routerName: 'myCenter' },
//           ],
//         },
//         {
//           name: '发现',
//           menu: [
//             { name: '应用广场', routerName: 'home' },
//           ],
//         },
//       ];
//       if (res.data.clientManage > 0) {
//         menuList[1].menu.push({ name: '客户端', routerName: 'clients' },)
//       }
//       if (res.data.customerManage > 0) {
//         menuList[1].menu.push({ name: '对话管理', routerName: 'chatManage' },)
//       }
//       if (res.data.memberNumber > 0) {
//         menuList[2].menu.push({ name: '团队空间', routerName: 'teamSpace' },)
//       }
//       store.action.updataRouterList(menuList)
//     }
//   })

//   // getUserPrivilege().then((res) => {
//   //   sessionStorage.setItem('privilege',res.data)
//   // })
// }
// 管理端的socket
const socket = new VueSocketIO({
  debug: false, // debug调试，生产建议关闭
  connection: `${websocketUrl}?token=${localStorage.getItem('token')}`,
  // connection: '/socket'
  // @ts-ignore
  options: {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 100,
    reconnectionDelay: 10000
  }
})
app.config.globalProperties.$socket = socket
// // 监听事件
app.config.globalProperties.$addSockets = registerSockets
// 移除事件
app.config.globalProperties.$removeSockets = destroySockets
app.config.globalProperties.$truncateString = truncateString
app.config.globalProperties.$echarts = echarts;
app.provide('$echarts', echarts)
//   web集成的socket
// console.log('localStorage.getItem(\'clientId\')', localStorage.getItem('clientId'))
const webSocket = new VueSocketIO({
  debug: false, // debug调试，生产建议关闭
  connection: `${websocketUrl}?clientId=${localStorage.getItem('clientId')}&sessionId=${localStorage.getItem('clientId')}`,
  // connection: '/socket'
  // @ts-ignore
  options: {
    autoConnect: false,
    reconnection: true,
    reconnectionAttempts: 100,
    reconnectionDelay: 10000
  }
})
app.config.globalProperties.$authIsShow = authIsShow
app.config.globalProperties.$webSocket = webSocket
// // 监听事件
app.config.globalProperties.$addWebSockets = registerWebSockets
// 移除事件
app.config.globalProperties.$removeWebSockets = destroyWebSockets
let setDialogWidth = ref(1980)
app.provide('setDialogWidth', setDialogWidth);
function resizeHandler() {
  setDialogWidth.value = window.innerWidth
};
window.addEventListener('resize', resizeHandler); // 监听窗口大小变化
resizeHandler()
// const regex = /\/app\/([^?]+)/;
// const match = localUrl.match(regex);
// if (match) {
//   const appCode = match[1];
//   if (isToken()) {
//     roleDetail({
//       code: appCode,
//     }).then((res: any) => {
//       if (res.success) {
//         const item = res.data;
//         store.action.updateUserList(item);
//         const data = {
//           person: 1,
//           text: item.introduction,
//           date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
//         };
//         const isFirst = true;
//         store.action.updateChatData(item.id, data, isFirst);
//         store.action.repeatNowSelect(item);
//         router.push({
//           name: "app",
//           params: { code: appCode },
//         });
//       } else {
//         router.push({
//           name: "home",
//         });
//       }
//     }).catch(() => {
//       router.replace({
//         name: "home",
//       });
//     })
//   }
// }

// const debounce = (fn: any, delay: any) => {
//   let timer: any = null;
//   return function (this: any) {
//     const context = this;
//     const args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   };
// }

// const _ResizeObserver = window.ResizeObserver;
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
//   constructor(callback: any) {
//     callback = debounce(callback, 16);
//     super(callback);
//   }
// }

