import {
  createRouter,
  // createWebHashHistory,
  RouteRecordRaw,
  createWebHistory,
} from "vue-router";
import { createStore } from '@/vuex/store';
import { ElMessage } from "element-plus";
// import { getRouterName } from '@/utils/utils';
import authMenu from '@/common/menuList.json';

// import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "portal",
    redirect: "/portal",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/portal/pLayout_new.vue"),
    children: [
      {
        path: "/portal",
        name: "portal",
        component: () => import("@/views/portal/components/portalPage.vue"),
      },
    ],
  },
  {
    path: "/portalNew",
    name: "portalNew",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/portal/pLayout_new.vue"),
    children: [
      {
        path: "/portalNew",
        name: "portalNew",
        component: () => import("@/views/portal/pLayout_new.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    redirect: "/home",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/HomeView/index.vue"),
    children: [
      {
        path: "/home",
        name: "HomeAll",
        component: () => import("@/views/HomeAll.vue"),
      },
      {
        path: "/square",
        name: "square",
        component: () => import("@/views/Square.vue"),
      },
      {
        path: "/app/:code",
        name: "app",
        component: () => import("@/views/HomeView/ChatView/index.vue"),
      },
      {
        path: "/paint",
        name: "paintPic",
        component: () => import("@/views/PaintPic.vue"),
      },
      {
        path: "/templates",
        name: "template",
        component: () => import("@/views/console/TemplateCenter/index.vue"),
      },
      {
        path: "/template/:code",
        name: "templateCode",
        component: () => import("@/views/console/TemplateCenter/TemplateChat.vue"),
      },
    ],
  },
  {
    path: "/console",
    name: "console",
    redirect: "/console/factory",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/console/index.vue"),
    children: [
      {
        path: "/console/factory",
        name: "myApp",
        component: () => import("@/views/console/MyApp/index.vue"),
        meta: {
          showPath: '/console/factory',
          tabName: '/ 创造 / 应用',
          titleName: '应用'
        }
      },
      {
        path: "/console/interface",
        name: "openInterface",
        component: () => import("@/views/console/AppInterface/OpenInterface.vue"),
        meta: {
          showPath: '/console/interface',
          tabName: '/ 接入 / 应用接入',
          titleName: '应用接入'
        }
      },
      // {
      //   path: "/console/dataAnalysis",
      //   name: "dataAnalysis",
      //   component: () => import("@/views/console/DataAnalysis.vue"),
      //   meta: {
      //     showPath: '/console/dataAnalysis',
      //     tabName: '/管理/数据看板'
      //   }
      // },

      {
        path: "/console/createApp",
        name: "createApp",
        component: () => import("@/views/console/CreateApp.vue"),
        meta: {
          showPath: '/console/factory',
          tabName: '/ 创造 / 应用 / 创建应用',
          titleName: '应用'
        }
      },
      {
        path: "/console/agent",
        name: "EmbeddingApp",
        component: () =>
          import("@/views/console/EmbeddingApp/index.vue"),
        meta: {
          showPath: '/console/factory',
          tabName: '/ 创造 / 应用 / 应用配置',
          titleName: '应用'
        }
      },

      {
        path: "/console/account",
        name: "myCenter",

        component: () => import("@/views/console/myCenter/MyCenter.vue"),
        meta: {
          showPath: '/console/account',
          tabName: '/ 管理 / 我的账户',
          titleName: '我的账户'
        }
      },


      {
        path: "/console/chatManage",
        name: "chatManage",
        component: () => import("@/views/console/ChatManage.vue"),
        meta: {
          showPath: '/console/chatManage',
          tabName: '/ 接入 / 对话管理',
          titleName: '对话管理'
        }
      },
      // {
      //   path: "/console/ChatIphone",
      //   name: "chatManage",
      //   component: () => import("@/views/console/ChatIphone.vue"),
      // },
      {
        path: "/console/clients",
        name: "clients",
        component: () => import("@/views/console/Clients/ClientsList.vue"),
        meta: {
          showPath: '/console/clients',
          tabName: '/ 接入 / 客户端',
          titleName: '客户端'
        }
      },
      {
        path: "/console/dataAnalysis",
        name: "dataAnalysis",
        component: () => import("@/views/console/DataAnalysis/index.vue"),
        meta: {
          showPath: '/console/dataAnalysis',
          tabName: '/ 管理 / 数据看板',
          titleName: '数据看板'
        }
      },
      {
        path: "/console/clients/configClient",
        name: "configurationClient",
        component: () => import("@/views/console/Clients/ConfigurationClient.vue"),
        meta: {
          showPath: '/console/clients',
          tabName: '/ 接入 / 客户端 / 客户端配置',
          titleName: '客户端'
        }
      },
      {
        path: "/console/clients/config",
        name: "configurationClientChat",
        component: () => import("@/views/console/Clients/ConfigurationClientChat.vue"),
        meta: {
          showPath: '/console/clients',
          tabName: '/ 接入 / 客户端 / 客户端配置',
          titleName: '客户端'
        }
      },
      {
        path: "/console/knowledgeBase",
        name: "knowledgeBase",
        component: () => import("@/views/console/KnowledgeBase/KnowledgeBase.vue"),
        meta: {
          showPath: '/console/knowledgeBase',
          tabName: '/ 创造 / 知识库',
          titleName: '知识库'
        }
      },
      {
        path: "/console/knowledgeBase/knowledgeManage",
        name: "KnowledgeManage",

        component: () => import("@/views/console/KnowledgeBase/KnowledgeManage.vue"),
        redirect: "/console/knowledgeBase/baseAll",
        children: [{
          path: "/console/knowledgeBase/baseAll",
          name: "baseAll",

          component: () => import("@/views/console/KnowledgeBase/BaseAll.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库 / 文件管理',
            titleName: '知识库'
          }
        },
        {
          path: "/console/knowledgeBase/baseFile",
          name: "baseFile",

          component: () => import("@/views/console/KnowledgeBase/BaseFile.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库',
            titleName: '知识库'
          }
        },
        {
          path: "/console/knowledgeBase/importFile",
          name: "importFile",

          component: () => import("@/views/console/KnowledgeBase/ImportFile.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库 / 文件导入',
            titleName: '知识库'
          }
        },
        {
          path: "/console/knowledgeBase/retrievalFile",
          name: "retrievalFile",

          component: () => import("@/views/console/KnowledgeBase/RetrievalFile.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库 / 检索测试',
            titleName: '知识库'
          }
        },
        {
          path: "/console/knowledgeBase/baseSetting",
          name: "baseSetting",

          component: () => import("@/views/console/KnowledgeBase/BaseSetting.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库 / 知识库配置',
            titleName: '知识库'
          }
        },
        {
          path: "/console/knowledgeBase/mediaResource",
          name: "mediaResource",
          component: () => import("@/views/console/KnowledgeBase/MediaResource.vue"),
          meta: {
            showPath: '/console/knowledgeBase',
            tabName: '/ 创造 / 知识库 / 素材管理',
            titleName: '知识库'
          }
        }]
      },
      {
        path: "/console/database",
        name: "database",
        component: () => import("@/views/console/DataBase/index.vue"),
        meta: {
          showPath: '/console/database',
          tabName: '/ 创造 / 数据库',
          titleName: '数据库'
        }
      },
      {
        path: "/console/database/navigation",
        name: "databaseNavigation",
        component: () => import("@/views/console/DataBase/databaseNavigation.vue"),
        redirect: "/console/database/databaseNavigation/preview",
        children: [{
          path: "/console/database/preview",
          name: "databasePreview",
          component: () => import("@/views/console/DataBase/databasePreview.vue"),
          meta: {
            showPath: '/console/database',
            tabName: '/ 创造 / 数据库 / 数据预览',
            titleName: '数据库'
          }
        }, {
          path: "/console/database/tableConfig",
          name: "tableConfig",
          component: () => import("@/views/console/DataBase/tableConfig.vue"),
          meta: {
            showPath: '/console/database',
            tabName: '/ 创造 / 数据库 / 数据表配置',
            titleName: '数据库'
          }
        }, {
          path: "/console/database/databaseConfig",
          name: "databaseConfig",
          component: () => import("@/views/console/DataBase/databaseConfig.vue"),
          meta: {
            showPath: '/console/database',
            tabName: '/ 创造 / 数据库 / 数据库配置',
            titleName: '数据库'
          }
        },
        ]
      },
      {
        path: "/console/plugins",
        name: "plugins",
        component: () => import("@/views/console/PluginAll.vue"),
        meta: {
          showPath: '/console/plugins',
          tabName: '/ 创造 / 插件',
          titleName: '插件'
        }
      },
      {
        path: "/console/templates",
        name: "templateCenter",
        component: () => import("@/views/console/TemplateCenter/index.vue"),
        meta: {
          showPath: '/console/templates',
          tabName: '/ 发现 / 模板中心',
          titleName: '模板中心'
        }
      },
      {
        path: "/console/template/:code",
        name: "templateChat",
        component: () => import("@/views/console/TemplateCenter/TemplateChat.vue"),
        meta: {
          showPath: '/console/templates',
          tabName: '/ 发现 / 模板中心 / 模板详情',
          titleName: '模板中心'
        }
      },
      {
        path: "/console/workflows",
        name: "flow",
        component: () => import("@/views/console/FlowWork.vue"),
        meta: {
          showPath: '/console/workflows',
          tabName: '/ 创造 / 工作流',
          titleName: '工作流'
        }
      },
      {
        path: "/console/plugins/detail",
        name: "customPlugin",
        component: () => import("@/views/console/PLuginManage/CustomPlugin.vue"),
        meta: {
          showPath: '/console/plugins',
          tabName: '/ 创造 / 插件 / 自定义插件',
          titleName: '插件'
        }
      },
      {
        path: "/console/teamSpace",
        name: "teamSpace",
        component: () => import("@/views/console/TeamSpace/index.vue"),
        meta: {
          showPath: '/console/teamSpace',
          tabName: '/ 管理 / 团队空间',
          titleName: '团队空间'
        }
      },
      {
        path: "/console/teamSpace/roleEdit",
        name: "roleEdit",
        component: () => import("@/views/console/TeamSpace/RightTree.vue"),
        meta: {
          showPath: '/console/teamSpace',
          tabName: '/ 管理 / 团队空间',
          titleName: '团队空间'
        }
      },
      {
        path: "/console/price",
        name: "price",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/RechargeView.vue"),
        meta: {
          showPath: '/console/customManage',
          tabName: '/购买',
          titleName: '购买'
        }
      },
      {
        path: "/console/customManage",
        name: "customManage",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/console/CustomManage/index.vue"),
        redirect: "/console/customManage/customerList",
        children: [{
          path: "/console/customManage/customerList",
          name: "customerList",
          component: () => import("@/views/console/CustomManage/compoents/CustomerList.vue"),
          meta: {
            showPath: '/console/customManage',
            tabName: '/ 管理 / 客户管理 / 客户列表',
            titleName: '客户管理'
          }
        }, {
          path: "/console/customManage/tagList",
          name: "tagList",
          component: () => import("@/views/console/CustomManage/compoents/TagList.vue"),
          meta: {
            showPath: '/console/customManage',
            tabName: '/ 管理 / 客户管理 / 标签管理',
            titleName: '客户管理'
          }
        }]
      },
    ],
  },
  {
    path: '/pluginKey',
    name: "pluginKey",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/pluginKey.vue"),
  },
  {
    path: '/console/workflow',
    name: "addFlow",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/vueFlow/VueFlow.vue"),
  },
  {
    path: '/benefits',
    name: "inviteActivi",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/InviteActivi.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/ChatView.vue"),
  },
  {
    path: "/web/linkAI/:clientId",
    name: "web",
    component: () =>
      import("@/views/console/ibotManage/iframeChat.vue"),
  },
  {
    path: "/web/iframe/:clientId",
    name: "fullweb",
    component: () =>
      import("@/views/console/ibotManage/iframeFullChat.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    //访问主页的时候 重定向到index页面
    redirect: "/404",
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/Not404.vue"),
  },
  {
    path: "/iframe",
    name: "iframes",
    component: () => import("@/views/console/ibotManage/iframeTest.vue"),
  }
];
//router/index.js

const router = createRouter({
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
  history: createWebHistory(),
  routes,
});


function findMenuByPath(menuList: any, path: any): any {
  for (const item of menuList) {
    // 检查当前项的路径
    if (item.path === path) {
      return item; // 找到匹配项
    }
    // 如果有子菜单，递归查找
    if (item.children) {
      const found = findMenuByPath(item.children, path);
      if (found) {
        return item; // 返回父菜单
      }
    }
  }
  return null; // 如果没有找到，返回 null
}
router.beforeEach((to: any, from, next) => {

  const store = createStore()
  const userDetail = store.state.user

  if (userDetail.authorizedRoute) {
    const allow = findMenuByPath(authMenu, to.path)
    try {
      if (allow.isAuthen && allow.isAuthen === 'Y') {
        const go = userDetail.authorizedRouteList.includes(allow.path)
        if (go) {
          next();
        } else {
          // ElMessage({
          //   type: 'warning',
          //   message: '暂无权限'
          // })
          next({ path: userDetail.authorizedRouteList[0] });
          return
        }
      } else {
        next()
      }
    } catch {
      next()
    }


    // console.log(7, userDetail.authorizedRouteList)
    // const a = getRouterName(userDetail.authorizedRouteList)

  } else {
    // if (to.name === 'clients' && store.state.userPrivige.clientManage < 1) {
    //   ElMessage({
    //     type: 'warning',
    //     message: '暂无权限'
    //   })
    //   return
    // }
    // if (to.name === 'chatManage' && store.state.userPrivige.customerManage < 1) {
    //   ElMessage({
    //     type: 'warning',
    //     message: '暂无权限'
    //   })
    //   return
    // }
    // if (to.name === 'teamSpace' && store.state.userPrivige.memberNumber < 1) {
    //   ElMessage({
    //     type: 'warning',
    //     message: '暂无权限'
    //   })
    //   return
    // }
    next();
  }

});
export default router;
