import { reactive } from "vue";

interface NowSelect {
  id?: number | null;
  name?: string;
  code?: string;
  thumb?: boolean;
  thumbCount?: number;
  collect?: boolean;
  collectCount?: number
}
export interface checkState {
  showVoice: boolean;
  user: any;
  chatData: any;
  userList: any;
  isPhone: boolean;
  nowSelect: NowSelect;
  token: string;
  balance?: number | null;
  weixin?: boolean
  searchTime?: number
  searchTimeInput?: string
  scoreTime?: number
  allModel?: any
  allPluginUser?: any
  workflowPlugin?: any
  weixinDesktop?: boolean
  selectchannel?: any
  selectReadType?: any
  customerList?: any
  sessionId?: string
  channelid?: string
  sessionIdchannelid?: string
  selectSessionIndex?: any
  earliestTimeDetail?: any
  privilege?: any
  clientId?: any
  routerList?: any
  userPrivige?: any
  inviteParam?: string
  headImageUrl?: string
  chatName?: string
  readType?: string
  chatUserDetail?: any
  isShowKuCode?: boolean
  flowElement?: any
  flowLine?: any
  flowData?: any
  draw?: boolean
  drawType: string | ''
  drawForm: any
  drawFormId: string
  flowAppCode: string
  runLoading: boolean
  runForm: object
  clickEdgeId: string
  clickActiveId: string
  flowName: string
  addLines: any
  applyList: any
  noticeNameList: any
  runNodeSatusList: any
  runEdgeSatusList: any
  isOpenAddNewbase: boolean
  numA: number
  phoneData: any
  customerData: any
  chatO: any
  nowSelectPlugin: any
  nowSelectModel: any
  homeMenuList: any
  clickChat: number
  openFlowHis: number
  flowForm: any
  activeFlow: boolean
  questionFlow: any
  saveTime: number
  saveAction: boolean
}
export const State: checkState = {
  user: {},
  chatData: [],
  userList: [],
  isPhone: false,
  customerData: '',
  showVoice: false,
  nowSelect: {
    id: null,
    name: "",
    code: "",
    thumb: false,
    thumbCount: 0,
    collect: false,
    collectCount: 0
  },
  token: "",
  balance: null,
  weixin: false,
  searchTime: 0,
  searchTimeInput: '',
  scoreTime: 0,
  allModel: [],
  allPluginUser: [],
  workflowPlugin: [],
  weixinDesktop: false,
  selectchannel: '',
  selectReadType: '',
  customerList: [],
  sessionId: '',
  channelid: '',
  sessionIdchannelid: '',
  selectSessionIndex: 0,
  earliestTimeDetail: '',
  privilege: '',
  clientId: '',
  homeMenuList: [
    { name: '首页', routerName: 'home' },
    { name: '对话', routerName: 'app' },
    { name: '绘画', routerName: 'paintPic' },
    { name: '模板', routerName: 'template' }
  ],
  routerList: [
    {
      name: '创造',
      id: '1',
      menu: [
        {
          name: '应用',
          routerName: 'myApp',
          isAvailable: true,
          hiddenMenu: false,
          id: '1-1',
          path: '/console/factory',
        },
        {
          name: '知识库',
          routerName: 'knowledgeBase',
          isAvailable: true,
          hiddenMenu: false,
          id: '1-2',
          path: '/console/knowledgeBase',
        },
        {
          name: '数据库',
          routerName: 'database',
          isAvailable: true,
          hiddenMenu: false,
          id: '1-3',
          path: '/console/database',
        },
        {
          name: '插件',
          routerName: 'plugins',
          isAvailable: true,
          hiddenMenu: false,
          id: '1-4',
          path: '/console/plugins',
        },
        {
          name: '工作流',
          routerName: 'flow',
          isAvailable: true,
          hiddenMenu: false,
          id: '1-5',
          path: '/console/workflows',
        },
      ],
    },
    {
      name: '接入',
      id: '2',
      menu: [
        {
          name: '应用接入',
          routerName: 'openInterface',
          isAvailable: true,
          hiddenMenu: false,
          id: '2-1',
          path: '/console/interface',
        },
        {
          name: '客户端',
          routerName: 'clients',
          isAvailable: false,
          hiddenMenu: false,
          content: '标准版',
          id: '2-2',
          path: '/console/clients',
        },
        {
          name: '客户管理',
          routerName: 'customManage',
          isAvailable: true,
          hiddenMenu: true,
          id: '2-3',
          path: '/console/customManage',
        },
        {
          name: '对话管理',
          routerName: 'chatManage',
          isAvailable: false,
          hiddenMenu: false,
          content: '专业版',
          id: '2-4',
          path: '/console/chatManage',
        },
      ],
    },
    {
      name: '管理',
      id: '3',
      menu: [
        // { name: '返回首页', routerName: 'home' },
        {
          name: '我的账户',
          routerName: 'myCenter',
          isAvailable: true,
          hiddenMenu: false,
          id: '3-1',
          path: '/console/account',
        },
        {
          name: '数据看板',
          routerName: 'dataAnalysis',
          isAvailable: true,
          hiddenMenu: false,
          content: '标准版',
          id: '3-2',
          path: '/console/dataAnalysis',
        },
        {
          name: '团队空间',
          routerName: 'teamSpace',
          isAvailable: false,
          hiddenMenu: false,
          content: '专业版',
          id: '3-3',
          path: '/console/teamSpace',
        },
      ],
    },
    {
      name: '发现',
      id: '4',
      menu: [
        {
          name: '模板中心',
          routerName: 'templateCenter',
          isAvailable: true,
          hiddenMenu: false,
          id: '4-1',
          path: '/console/templates',
        },
        {
          name: '应用广场',
          routerName: 'home',
          isAvailable: true,
          hiddenMenu: false,
          id: '4-2',
          path: '/home',
        },
      ],
    },
  ],
  userPrivige: '',
  inviteParam: '',
  headImageUrl: '',
  chatName: '',
  readType: '',
  chatUserDetail: '',
  isShowKuCode: false,
  flowElement: [],
  flowLine: [],
  flowData: [],
  draw: false,
  drawType: '',
  drawForm: '',
  flowAppCode: '',
  runLoading: false,
  runForm: {},
  drawFormId: '',
  clickEdgeId: '',
  clickActiveId: '',
  flowName: '',
  addLines: {
    source: '',
    sourceHandle: ''
  },
  applyList: '',
  noticeNameList: [],
  runNodeSatusList: [],
  runEdgeSatusList: [],
  isOpenAddNewbase: false,
  numA: 0,
  phoneData: '',
  chatO: '',
  nowSelectPlugin: [],
  nowSelectModel: [],
  clickChat: 0,
  openFlowHis: 0,
  flowForm: "",
  activeFlow: false,
  questionFlow: [],
  saveTime: 0,
  saveAction: false
};
export function createState() {
  return reactive(State);
}
