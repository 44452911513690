export const registerSockets = (sockets: any, proxy: any) => {
  sockets &&
    Object.keys(sockets).forEach((t) => {
      t !== 'subscribe' &&
        t !== 'unsubscribe' &&
        proxy.$socket.emitter.addListener(t, sockets[t], proxy)
    })
}

export const destroySockets = (sockets: any, proxy: any) => {
  sockets &&
    Object.keys(sockets).forEach((t) => {
      proxy.$socket.emitter.removeListener(t, proxy)
    })
}

export const registerWebSockets = (sockets: any, proxy: any) => {
  sockets &&
  Object.keys(sockets).forEach((t) => {
    t !== 'subscribe' &&
    t !== 'unsubscribe' &&
    proxy.$webSocket.emitter.addListener(t, sockets[t], proxy)
  })
}

export const destroyWebSockets = (sockets: any, proxy: any) => {
  sockets &&
  Object.keys(sockets).forEach((t) => {
    proxy.$webSocket.emitter.removeListener(t, proxy)
  })
}