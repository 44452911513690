
import store from "@/store";
import { checkState } from "./state";
import router from "@/router";
import { focusNode } from "element-plus/es/utils";
import { NodeTypes } from '@/views/vueFlow/utils/nodeFlow';
import { deepClone } from '@/utils/utils'
// import { fetchModule } from "vite";

// import Storage from '@/utils/localStorage'
function updateUser(state: checkState) {
  return (user: any) => {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  };
}
function updateUserCompanyCode(state: checkState) {
  return (companyCode: any) => {
    state.user['companyCode'] = companyCode;
    localStorage.setItem("user", JSON.stringify(state.user));
  };
}

function updateUserCompanyOwner(state: checkState) {
  return (companyOwner: any) => {
    state.user['companyOwner'] = companyOwner;
    localStorage.setItem("user", JSON.stringify(state.user));
  };
}
function updateUserCompanyName(state: checkState) {
  return (companyName: any) => {
    state.user['companyName'] = companyName;
    localStorage.setItem("user", JSON.stringify(state.user));
  };
}
function updateUserList(state: checkState) {
  return (userList: any) => {
    // 通过id查找该应用在userList 的位置
    const indexId = state.userList.findIndex(
      (item: any) => item.id === userList.id
    );
    // 不存在则添加 存在则替换
    if (indexId === -1) {
      state.userList.unshift(userList);
    } else {
      state.userList.splice(indexId, 1, userList);
    }
    localStorage.setItem("userList", JSON.stringify(state.userList));
  };
}
function updateUserListDefaultModel(state: checkState) {
  return (appCode: string, defaultModel: any) => {
    const indexId = state.userList.findIndex(
      (item: any) => item.id === appCode
    );
    state.userList[indexId].default_model = defaultModel
    localStorage.setItem("userList", JSON.stringify(state.userList));
  };
}
function updataUserListTC(state: checkState) {
  return (appCode: string, name: string, isTrue: boolean) => {
    const index = state.userList.findIndex(
      (item: any) => item.id === appCode
    );
    switch (name) {
      case 'thumb':
        state.userList[index].thumb = !state.userList[index].thumb
        // state.nowSelect.thumb = !state.nowSelect.thumb
        // console.log(state.nowSelect.thumb)
        // console.log(state.nowSelect.thumb)
        // state.nowSelect.thumb = !state.nowSelect.thumb
        if (isTrue) {
          state.userList[index].thumbCount -= 1

          // if(state.nowSelect.thumbCount)    state.nowSelect.thumbCount -= 1
        } else {
          state.userList[index].thumbCount += 1

          // if(state.nowSelect.thumbCount)    state.nowSelect.thumbCount += 1
        }
        break;
      case 'collect':
        state.userList[index].collect = !state.userList[index].collect
        // state.nowSelect.collect = !state.nowSelect.collect
        if (isTrue) {
          state.userList[index].collectCount -= 1
          // if(state.nowSelect.collectCount)    state.nowSelect.collectCount -= 1
        } else {
          state.userList[index].collectCount += 1
          // if(state.nowSelect.collectCount)    state.nowSelect.collectCount += 1
        }
        break;
    }
    localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
    localStorage.setItem("userList", JSON.stringify(state.userList));
  }
}
function repeatUserList(state: checkState) {
  return (userList: any) => {
    state.userList = userList;
  };
}

function repeatNowSelect(state: checkState) {
  return (nowSelect: any) => {
    state.nowSelect = nowSelect;
    localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
  };
}

function repeatChatData(state: checkState) {
  return (chatData: any) => {
    state.chatData = chatData;
  };
}
function checkPhone(state: checkState) {
  return (isPhone: any) => {
    state.isPhone = isPhone;
  };
}
function checkVoice(state: checkState) {
  return (showVoice: any) => {
    state.showVoice = showVoice;
  };
}
function updateChatData(state: checkState) {
  return (
    id: any,
    data: any,
    isFirst = false,
    replaceLastData = false
  ) => {
    const indexFind = state.chatData.findIndex((item: any) => {
      return item.roleId === id;
    });
    if (indexFind === -1) {
      state.chatData.unshift({ roleId: id, data: [data] });
    } else {
      if (replaceLastData) {
        state.chatData[indexFind].data[
          state.chatData[indexFind].data.length - 1
        ] = data;
      } else {
        if (!isFirst) state.chatData[indexFind].data.push(data);
      }
    }
    localStorage.setItem("chatData", JSON.stringify(state.chatData));
  };
}

function setToken(state: checkState) {
  return (token: any) => {
    state.token = token;
    localStorage.setItem("token", state.token);
  };
}

function clearToken(state: checkState) {
  return () => {
    state.token = "";
    localStorage.removeItem("token");
  };
}
function setClientId(state: checkState) {
  return (clientId: any) => {
    state.clientId = clientId;
    localStorage.setItem("clientId", state.clientId);
  };
}

function clearClientId(state: checkState) {
  return () => {
    state.clientId = "";
    localStorage.removeItem("clientId");
  };
}

function clearState(state: checkState) {
  return () => {
    state.user = {};
    state.chatData = [];
    state.userList = [];
    state.nowSelect = {};
    state.token = "";
  };
}
function deleteChatRole(state: checkState) {
  return (chatRole: any, index: number) => {
    const userListIndex = state.userList.findIndex((item: any) => item.id === chatRole.id);
    const chatDataIndex = state.chatData.findIndex((item: any) => item.roleId === chatRole.id);
    state.userList.splice(userListIndex, 1);
    localStorage.setItem("userList", JSON.stringify(state.userList));
    state.chatData.splice(chatDataIndex, 1);
    localStorage.setItem("chatData", JSON.stringify(state.chatData));

    if (chatRole.id !== state.nowSelect.id) return;
    if (state.userList.length > 0) {
      if (index > 0) {
        state.nowSelect = state.userList[index - 1];
      } else {
        state.nowSelect = state.userList[0];
      }
      localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
      router.push({
        name: "app",
        params: { code: state.nowSelect.code },
      });
    } else {
      state.nowSelect = {};
      localStorage.setItem("nowSelect", JSON.stringify(state.nowSelect));
      router.push({
        name: "home",
      });
    }
  };
}

function updataBalance(state: checkState) {
  return (balance: any) => {
    state.balance = balance;
  };
}
function upDataIsWexin(state: checkState) {
  return (isWxin: any) => {
    state.weixin = isWxin;
  };
}
function upDateSearchTime(state: checkState) {
  return (searchTime: number) => {
    state.searchTime = searchTime;
  };
}
function upDateSearchInput(state: checkState) {
  return (searchTimeInput: string) => {
    state.searchTimeInput = searchTimeInput;
  };
}
function upDateScoreTime(state: checkState) {
  return (scoreTime: number) => {
    state.scoreTime = scoreTime;
  };
}

function updataAllModel(state: checkState) {
  return (modelList: any) => {
    state.allModel = modelList
  }
}
function repeatAllPlugin(state: checkState) {
  return (allPlugin: any) => {
    state.allPluginUser = allPlugin
  }
}
function updataWorkFlowPlugin(state: checkState) {
  return (workflowPlugin: any) => {
    state.workflowPlugin = workflowPlugin
  }
}
function updataAllPlugin(state: checkState) {
  return (allPlugin: any) => {
    const pluginList = state.allPluginUser
    allPlugin.forEach((item2: any) => {
      // 检查第一个数组中是否存在相同的code
      const index1 = pluginList.findIndex((item1: any) => item1.code === item2.code);
      if (index1 !== -1) {
        // 如果存在相同的code，则使用第一个数组中的值
        pluginList[index1] = item2;
      } else {
        // 如果第一个数组中不存在相同的code，则将元素添加到第一个数组中
        pluginList.push(item2);
      }
    });
    // 遍历第一个数组
    pluginList.forEach((item1: any, index1: number) => {
      // 检查第二个数组中是否存在相同的code
      const index2 = allPlugin.findIndex((item2: any) => item1.code === item2.code);
      if (index2 === -1) {
        // 如果第二个数组中不存在相同的code，则从第一个数组中删除该元素
        pluginList.splice(index1, 1);
      }
    });
    state.allPluginUser = pluginList
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}

function replacePluginEnabled(state: checkState) {
  return (allPlugin: any) => {
    allPlugin.forEach((item2: any) => {
      const index1 = state.allPluginUser.findIndex((item1: any) => item1.code === item2.code)
      // 若此时本地用户插件处于关闭状态
      if (index1 > -1 && !state.allPluginUser[index1].enabled) {
        state.allPluginUser[index1].enabled = item2.enabled
      }
    })
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}
function checkWechatDesktop(state: checkState) {
  return (val: boolean) => {
    state.weixinDesktop = val
  }
}
function updateEnabled(state: checkState) {
  return (e: string, plugin: any) => {
    const index1 = state.allPluginUser.findIndex((item1: any) => item1.code === plugin.code)
    if (index1 > -1) {
      state.allPluginUser[index1].enabled = e
    }
    localStorage.setItem("allPluginUser", JSON.stringify(state.allPluginUser));
  }
}
function updataSelectchannel(state: checkState) {
  return (selectchannel: any) => {
    state.selectchannel = selectchannel
    localStorage.setItem('selectchannel', JSON.stringify(selectchannel))
  }
}
function updataSelectReadType(state: checkState) {
  return (selectReadType: any) => {
    state.selectReadType = selectReadType
  }
}
function clearCustomerList(state: checkState) {
  return () => {
    state.customerList = []
  }
}
// 将新的客服列表与之前的客服列表拼接
function repaeatCustomerList(state: checkState) {
  return (customerList: any) => {
    state.customerList = state.customerList.concat(customerList)
  }
}
function updataChatName(state: checkState) {
  return (chatName: any) => {
    state.chatName = chatName
  }
}
function updateChatUserDetail(state: checkState) {
  return (chatUserDetail: any) => {
    state.chatUserDetail = chatUserDetail
  }
}
// websocket 更新客服列表
function updataCustomerList(state: checkState) {
  return (customerData: any) => {

    // 判断 客服消息中的
    // if ((state.selectchannel === '' || customerData.channelId === state.selectchannel) && (state.selectReadType === '' || state.selectReadType === 'HAVE_READ')) {
    //   const isT = state.customerList.findIndex((obj: any) => obj.sessionId === customerData.sessionId)
    //   if (isT > -1) {
    //     state.customerList.splice(isT, 1)
    //   }
    //   state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent })
    //   if (customerData.sessionId !== state.sessionId && (isT > state.selectSessionIndex || isT === -1)) {
    //     // @ts-ignore
    //     state.selectSessionIndex = state.selectSessionIndex + 1
    //   }
    // }
    if (customerData.deliverMsgType === 'MANUAL_NOTICE') {
      const isT = state.customerList.findIndex((obj: any) => obj.sessionId === customerData.sessionId)
      if (isT > -1) {
        state.customerList[isT].serviceMode = 'MANUAL_MODE'


        if (state.chatUserDetail.sessionId === customerData.sessionId) {
          // updataServiceMode(customerData.sessionId, 'MANUAL_MODE')
          const chatUserDetail = JSON.parse(JSON.stringify(state.chatUserDetail))
          chatUserDetail.serviceMode = 'MANUAL_MODE'
          state.chatUserDetail = chatUserDetail
        }
      }
      return
    }


    if (state.selectchannel === '' || customerData.channelId === state.selectchannel) {
      if (state.selectReadType === '') {
        const isT = state.customerList.findIndex((obj: any) => obj.sessionId === customerData.sessionId)
        const msgDetail = state.customerList[isT]
        // 删除左侧列表里面的数据
        if (isT > -1) {
          state.customerList.splice(isT, 1)
        }

        // 将数据添加到左侧顶部
        if (msgDetail) {
          if (msgDetail.isGroup && msgDetail.isGroup === 'Y') {
            // 添加群聊
            state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
          } else {
            if (!msgDetail.groupName) {
              let readStatus = 'UNREAD'
              if (customerData.readStatus) {
                readStatus = customerData.readStatus
              }

              // 添加个人
              state.customerList.unshift({ ...customerData, readStatus: readStatus, lastMsgContent: customerData.msgContent, senderName: msgDetail.senderName, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            } else {
              // 添加群聊

              state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            }
          }
        } else {
          let readStatus = 'UNREAD'
          if (customerData.readStatus) {
            readStatus = customerData.readStatus
          }
          state.customerList.unshift({ ...customerData, readStatus: readStatus, lastMsgContent: customerData.msgContent })
        }


        // 判断光标是否移动
        if (customerData.sessionId !== state.sessionId && (isT > state.selectSessionIndex || isT === -1)) {
          // @ts-ignore
          state.selectSessionIndex = state.selectSessionIndex + 1
        } else if (customerData.sessionId === state.sessionId) {
          state.selectSessionIndex = 0
        }
      } else if (state.selectReadType === 'HAVE_READ') {
        const isT = state.customerList.findIndex((obj: any) => obj.sessionId === customerData.sessionId)
        if (isT > -1) {
          const msgDetail = state.customerList[isT]

          state.customerList.splice(isT, 1)
          if (customerData.isGroup && customerData.isGroup === 'Y') {
            state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
          } else {
            if (!customerData.groupName) {
              state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, senderName: msgDetail.senderName, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            } else {
              state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            }
          }

          if (customerData.sessionId !== state.sessionId && (isT > state.selectSessionIndex || isT === -1)) {
            // @ts-ignore
            state.selectSessionIndex = state.selectSessionIndex + 1
          } else if (customerData.sessionId === state.sessionId) {
            state.selectSessionIndex = 0
          }
        }
      } else if (state.selectReadType === 'UNREAD') {
        const isT = state.customerList.findIndex((obj: any) => obj.sessionId === customerData.sessionId)

        const msgDetail = state.customerList[isT]
        if (isT > -1) {
          state.customerList.splice(isT, 1)
        }
        if (msgDetail) {
          if (customerData.isGroup && customerData.isGroup === 'Y') {
            state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
          } else {
            if (!customerData.groupName) {
              state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, senderName: msgDetail.senderName, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            } else {
              state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent, headImageUrl: msgDetail.headImageUrl, serviceMode: msgDetail.serviceMode })
            }
          }
        } else {
          state.customerList.unshift({ ...customerData, readStatus: 'UNREAD', lastMsgContent: customerData.msgContent })
        }

        if (customerData.sessionId !== state.sessionId && (isT > state.selectSessionIndex || isT === -1)) {
          // @ts-ignore
          state.selectSessionIndex = state.selectSessionIndex + 1
        } else if (customerData.sessionId === state.sessionId) {
          state.selectSessionIndex = 0
        }
      }
    }
  }
}
function updataCustomerListRead(state: checkState) {
  return (sessionId: any) => {
    const isT = state.customerList.findIndex((obj: any) => obj.sessionId === sessionId)
    if (isT > -1) {
      state.customerList[isT].readStatus = 'HAVE_READ'
    }
  }
}
function getSessionId(state: checkState) {
  return (sessionId: any) => {
    // console.log(sessionId, state.sessionId)
    // if (sessionId === state.sessionId) {
    //   state.sessionId = ''
    //   setTimeout(() => {
    //     state.sessionId = sessionId
    //   }, 100);
    // } else {
    //   state.sessionId = sessionId
    // }
    state.sessionId = sessionId
  }
}

function getSessionIdChannelid(state: checkState) {
  return (sessionIdchannelid: any) => {
    state.sessionIdchannelid = sessionIdchannelid
  }
}
function getChannelid(state: checkState) {
  return (channelid: any) => {
    state.channelid = channelid
  }
}
function updataChatHeadImg(state: checkState) {
  return (headImageUrl: any) => {
    state.headImageUrl = headImageUrl
  }
}
function updataSessionIndex(state: checkState) {
  return (index: any) => {
    state.selectSessionIndex = index
  }
}

function updataEarliestTimeDetail(state: checkState) {
  return (time: any) => {
    state.earliestTimeDetail = time
  }
}
function updataPrivige(state: checkState) {
  return (privilege: any) => {
    state.privilege = privilege
  }
}

function updataRouterList(state: checkState) {
  return (routerList: any) => {
    state.routerList = routerList
    // sessionStorage.setItem('routerList', JSON.stringify(state.routerList))
  }
}
function updataHomeMenuList(state: checkState) {
  return (homeMenuList: any) => {
    state.homeMenuList = homeMenuList
    // sessionStorage.setItem('routerList', JSON.stringify(state.routerList))
  }
}
function updataUserPrivige(state: checkState) {
  return (userPrivige: any) => {
    state.userPrivige = userPrivige
    sessionStorage.setItem('userPrivige', JSON.stringify(state.userPrivige))
  }
}

function updataInviteParam(state: checkState) {
  return (InviteParam: any) => {
    state.inviteParam = InviteParam
  }
}
function updataReadType(state: checkState) {
  return (readType: any) => {
    state.readType = readType
  }
}

function updataServiceMode(state: checkState) {
  return (sessionId: any, serviceMode: string) => {
    const isT = state.customerList.findIndex((obj: any) => obj.sessionId === sessionId)
    if (isT > -1) {
      state.customerList[isT].serviceMode = serviceMode
      const chatUserDetail = JSON.parse(JSON.stringify(state.chatUserDetail))
      chatUserDetail.serviceMode = serviceMode
      state.chatUserDetail = chatUserDetail
    }
  }
}
function updataKuCode(state: checkState) {
  return (isShowKuCode: any) => {
    state.isShowKuCode = isShowKuCode
  }
}
function upDateFlowElementStart(state: checkState) {
  return (flowElement: any) => {
    state.flowElement = flowElement
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowElement(state: checkState) {
  return (flowElement: any) => {
    state.flowElement.push(flowElement)
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowData(state: checkState) {
  return (flowData: any) => {
    state.flowData = flowData
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowElementTypeDesc(state: checkState) {
  return (id: any, input: string) => {
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })
    state.flowElement[index].config['desc'] = input
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}

function upDateFlowElementTypeName(state: checkState) {
  return (id: any, input: string) => {
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })
    state.flowElement[index].config['name'] = input
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function saveFlowElement(state: checkState) {
  return (flowElement: any) => {
    state.flowElement = flowElement
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowElementPosition(state: checkState) {
  return (id: any, position: any) => {
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })

    state.flowElement[index].position = position
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}

function upDataFLowConfig(state: checkState) {
  return (id: any, config: any) => {
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })

    for (const key in config) {
      state.flowElement[index].config[key] = config[key]

    }
  }
}
function upDateFlowElementConfig(state: checkState) {
  return (id: any, configs: any) => {
    const config = deepClone(configs)
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })
    if (state.flowElement[index].type === NodeTypes.LOGIC_BRANCH.type) {
      config.branches.forEach((item: any) => {
        state.flowElement[index].config.branches.forEach((items: any) => {
          if (item.id === items.id) {
            item.node_id = items.node_id
          }
        })
      })
      state.flowElement[index].config = { desc: state.flowElement[index].config.desc, ...config, name: state.flowElement[index].config.name }
    } else if (state.flowElement[index].type === NodeTypes.INTENT_BRANCH.type) {
      config.intentions.forEach((item: any) => {
        state.flowElement[index].config.intentions.forEach((items: any) => {
          if (item.id === items.id) {
            item.node_id = items.node_id
          }
        })
      })
      state.flowElement[index].config = { desc: state.flowElement[index].config.desc, ...config, name: state.flowElement[index].config.name }
    } else {
      state.flowElement[index].config = { desc: state.flowElement[index].config.desc, ...config, name: state.flowElement[index].config.name }
    }

    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowElementIntentNode(state: checkState) {
  return (id: any, handeleId: string, targetId: string) => {
    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })
    if (state.flowElement[index].type === NodeTypes.INTENT_BRANCH.type) {
      const intentIndex = state.flowElement[index].config.intentions.findIndex((item: any) => {
        return item.id === handeleId
      })
      console.log(89, targetId)
      state.flowElement[index].config.intentions[intentIndex].node_id = targetId
    }
    if (state.flowElement[index].type === NodeTypes.LOGIC_BRANCH.type) {

      const intentIndex = state.flowElement[index].config.branches.findIndex((item: any) => {
        return item.id === handeleId
      })
      state.flowElement[index].config.branches[intentIndex].node_id = targetId
    }
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowLineStart(state: checkState) {
  return (flowLine: any) => {
    state.flowLine = flowLine
    // localStorage.setItem('flowElement', JSON.stringify(state.flowElement))
  }
}
function upDateFlowLine(state: checkState) {
  return (flowLine: any) => {
    state.flowLine.push(flowLine)
    // localStorage.setItem('flowLine', JSON.stringify(state.flowLine))
  }
}
function saveFlowLine(state: checkState) {
  return (flowLine: any) => {
    state.flowLine = flowLine
    // localStorage.setItem('flowLine', JSON.stringify(state.flowLine))
  }
}
function updataDraw(state: checkState) {
  return (draw: any) => {
    state.draw = draw
  }
}
function updataDrawForm(state: checkState) {
  return (id: any,) => {
    if (id) {
      let drawFormTest: any = {}
      state.drawFormId = id
      if (id === 'runTest') {
        drawFormTest['runType'] = 'FLOW'
      } else if (id === 'runTime') {
        drawFormTest['runType'] = 'TIME'
      } else {
        const index = state.flowElement.findIndex((item: any) => {
          return item.id === id
        })
        drawFormTest = state.flowElement[index]
        drawFormTest['runType'] = 'NODE'
      }
      state.drawForm = drawFormTest
    } else {
      state.drawForm = ''
    }
  }
}
function deleteFlowLine(state: checkState) {
  return (flowLineId: any) => {
    const index = state.flowLine.findIndex((item: any) => {
      return item.id === flowLineId
    })
    state.flowLine.splice(index, 1)
    // localStorage.setItem('flowLine', JSON.stringify(state.flowLine))
  }
}
function deleteFlowIntent(state: checkState) {
  return (id: any, inteId: any) => {

    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })

    const intentIndex = state.flowElement[index].config.intentions.findIndex((item: any) => {
      return item.id === inteId
    })
    state.flowElement[index].config.intentions.splice(intentIndex, 1)
    // localStorage.setItem('flowLine', JSON.stringify(state.flowLine))
  }
}
function addFlowIntent(state: checkState) {
  return (id: any, intente: any) => {

    const index = state.flowElement.findIndex((item: any) => {
      return item.id === id
    })

    // const intentIndex = state.flowElement[index].config.intentions.findIndex((item: any) => {
    //   return item.id === inteId
    // })
    state.flowElement[index].config.intentions.push(intente)
    // localStorage.setItem('flowLine', JSON.stringify(state.flowLine))
  }
}

function deleteNodeLine(state: checkState) {
  return (id: any, lineId: any) => {
    state.flowElement = state.flowElement.filter((obj: any) => {
      return obj.id !== id
    });
    const idsToRemove = lineId.map((obj: any) => {
      return obj.id
    });
    idsToRemove.forEach((id: any) => {
      state.flowLine = state.flowLine.filter((obj: any) => {
        if (obj.sourceHandle.includes('handle')) {
          if (obj.id === id) {
            const dataIndex = state.flowElement.findIndex((items: any) => items.id === obj.source)
            if (state.flowElement[dataIndex].type === NodeTypes.INTENT_BRANCH.type) {
              const intenteIndex = state.flowElement[dataIndex].config.intentions.findIndex((itema: any) => itema.id === obj.sourceHandle)
              state.flowElement[dataIndex].config.intentions[intenteIndex].node_id = ''
            } else if (state.flowElement[dataIndex].type === NodeTypes.LOGIC_BRANCH.type) {
              const intenteIndex = state.flowElement[dataIndex].config.branches.findIndex((itema: any) => itema.id === obj.sourceHandle)
              state.flowElement[dataIndex].config.branches[intenteIndex].node_id = ''
            }
          }
        }
        return obj.id !== id
      });
    })
  }
}
function deleteLine(state: checkState) {
  return (id: any,) => {
    state.flowLine = state.flowLine.filter((obj: any) => {
      if (obj.sourceHandle.includes('handle')) {
        if (obj.id === id) {
          const dataIndex = state.flowElement.findIndex((items: any) => items.id === obj.source)
          if (state.flowElement[dataIndex].type === NodeTypes.INTENT_BRANCH.type) {
            const intenteIndex = state.flowElement[dataIndex].config.intentions.findIndex((itema: any) => itema.id === obj.sourceHandle)
            state.flowElement[dataIndex].config.intentions[intenteIndex].node_id = ''
          } else if (state.flowElement[dataIndex].type === NodeTypes.LOGIC_BRANCH.type) {
            const intenteIndex = state.flowElement[dataIndex].config.branches.findIndex((itema: any) => itema.id === obj.sourceHandle)
            state.flowElement[dataIndex].config.branches[intenteIndex].node_id = ''
          }
        }
      }
      return obj.id !== id
    });
  }
}

function saveFlowAppCode(state: checkState) {
  return (flowAppCode: any) => {
    state.flowAppCode = flowAppCode
  }
}

function uploadRunLoading(state: checkState) {
  return (runLoading: any) => {
    state.runLoading = runLoading
  }
}
function uploadRunForm(state: checkState) {
  return (runForm: any) => {
    state.runForm = runForm
  }
}

function uploadClick(state: checkState) {
  return (clickEdgeId: any) => {
    state.clickEdgeId = clickEdgeId
  }
}

function upDataActiveId(state: checkState) {
  return (clickActiveId: any) => {
    state.clickActiveId = clickActiveId
  }
}

function upDataFLowName(state: checkState) {
  return (flowName: any) => {
    state.flowName = flowName
  }
}
function upDataAddLine(state: checkState) {
  return (addLines: any) => {
    state.addLines = addLines
  }
}

function getApplyList(state: checkState) {
  return (applyList: any) => {
    state.applyList = applyList
  }
}

function getNoticeNameList(state: checkState) {
  return (noticeNameList: any) => {
    state.noticeNameList = noticeNameList
  }
}
function updataRunStaus(state: checkState) {
  return (runNodeSatusList: any) => {
    if (!runNodeSatusList) {
      state.runNodeSatusList = []
    } else {
      state.runNodeSatusList.push(runNodeSatusList)
      // if (state.runNodeSatusList.length > 1) {
      //   const status = runNodeSatusList.run_status
      //   const nodes = [state.runNodeSatusList[state.runNodeSatusList.length - 2], state.runNodeSatusList[state.runNodeSatusList.length - 1]]
      //   const lines = findConnection(nodes, state.flowLine)
      //   lines['status'] = status
      //   state.runEdgeSatusList.push(lines)
      // } else {
      //   state.runEdgeSatusList = []
      // }
    }
  }
}
function openNewBase(state: checkState) {
  return (isOpenAddNewbase: any) => {
    state.isOpenAddNewbase = isOpenAddNewbase
  }
}
function findConnection(nodes: any, connections: any) {
  // 先确保nodes数组中有两个节点
  if (nodes.length !== 2) {
    throw new Error("Nodes array must contain exactly two elements.");
  }

  // 从nodes数组中提取node_id
  const [node1, node2] = nodes.map((node: any) => node.node_id);
  // 在connections数组中查找匹配的连接
  return connections.find((connection: any) => {
    return connection.source === node1 && connection.target === node2
      || null;
  })
}

function upDataChat(state: checkState) {
  return () => {
    state.numA += 1
  }
}
function upPhoneChat(state: checkState) {
  return (phoneData: any) => {
    state.phoneData = phoneData
  }
}

function chatDataUp(state: checkState) {

  return (customerData: any) => {
    state.customerData = customerData
  }
}
function chatDataChatO(state: checkState) {
  return (chatO: any) => {
    state.chatO = chatO
  }
}
function updateNowSelectPlugin(state: checkState) {
  return (nowSelectPlugin: any) => {
    state.nowSelectPlugin = nowSelectPlugin
  }
}
function updateNowSelectModel(state: checkState) {
  return (nowSelectModel: any) => {
    state.nowSelectModel = nowSelectModel
  }
}
function upDateClickChat(state: checkState) {
  return () => {
    state.clickChat += 1
  }
}
function upDataOpenFlowHis(state: checkState) {
  return () => {
    state.openFlowHis += 1
  }
}
function upDataFlowDetail(state: checkState) {
  return (flowForm: any) => {
    state.flowForm = flowForm
  }
}
function upDataActiveFlow(state: checkState) {
  return (activeFlow: any) => {
    state.activeFlow = activeFlow
  }
}
function updataQuestion(state: checkState) {
  return (questionFlow: any) => {
    state.questionFlow = questionFlow
  }
}
// 用来计算保存的次数,通过监听保存的次数的改变，来判断保存按钮的触发
function updataSavetime(state: checkState) {
  return () => {
    state.saveTime += 1
  }
}
function saveFlowAciton(state: checkState) {
  return (state: any) => {
    state.saveAction = state
  }
}
/**
 * 创建Action
 * @param state
 */
export function createAction(state: checkState) {
  return {
    updateUser: updateUser(state),
    updateUserCompanyCode: updateUserCompanyCode(state),
    updateUserList: updateUserList(state),
    updateUserListDefaultModel: updateUserListDefaultModel(state),
    updateChatData: updateChatData(state),
    checkPhone: checkPhone(state),
    checkVoice: checkVoice(state),
    repeatUserList: repeatUserList(state),
    repeatNowSelect: repeatNowSelect(state),
    repeatChatData: repeatChatData(state),
    setToken: setToken(state),
    clearState: clearState(state),
    updataBalance: updataBalance(state),
    clearToken: clearToken(state),
    deleteChatRole: deleteChatRole(state),
    upDataIsWexin: upDataIsWexin(state),
    upDateSearchTime: upDateSearchTime(state),
    upDateSearchInput: upDateSearchInput(state),
    upDateScoreTime: upDateScoreTime(state),
    updataUserListTC: updataUserListTC(state),
    updataAllModel: updataAllModel(state),
    updataAllPlugin: updataAllPlugin(state),
    repeatAllPlugin: repeatAllPlugin(state),
    replacePluginEnabled: replacePluginEnabled(state),
    updateEnabled: updateEnabled(state),
    checkWechatDesktop: checkWechatDesktop(state),
    updataSelectchannel: updataSelectchannel(state),
    updataSelectReadType: updataSelectReadType(state),
    updataCustomerList: updataCustomerList(state),
    repaeatCustomerList: repaeatCustomerList(state),
    clearCustomerList: clearCustomerList(state),
    getSessionId: getSessionId(state),
    updataSessionIndex: updataSessionIndex(state),
    updataEarliestTimeDetail: updataEarliestTimeDetail(state),
    updataCustomerListRead: updataCustomerListRead(state),
    updataPrivige: updataPrivige(state),
    setClientId: setClientId(state),
    clearClientId: clearClientId(state),
    updataRouterList: updataRouterList(state),
    getChannelid: getChannelid(state),
    updateUserCompanyOwner: updateUserCompanyOwner(state),
    updataUserPrivige: updataUserPrivige(state),
    updataInviteParam: updataInviteParam(state),
    updataChatHeadImg: updataChatHeadImg(state),
    updataChatName: updataChatName(state),
    updataReadType: updataReadType(state),
    updateChatUserDetail: updateChatUserDetail(state),
    updataServiceMode: updataServiceMode(state),
    updataKuCode: updataKuCode(state),
    upDateFlowElement: upDateFlowElement(state),
    upDateFlowElementStart: upDateFlowElementStart(state),
    upDateFlowLineStart: upDateFlowLineStart(state),
    upDateFlowLine: upDateFlowLine(state),
    updataDraw: updataDraw(state),
    deleteFlowLine: deleteFlowLine(state),
    upDateFlowElementPosition: upDateFlowElementPosition(state),
    saveFlowElement: saveFlowElement(state),
    saveFlowLine: saveFlowLine(state),
    updataDrawForm: updataDrawForm(state),
    upDateFlowElementTypeName: upDateFlowElementTypeName(state),
    upDateFlowElementTypeDesc: upDateFlowElementTypeDesc(state),
    saveFlowAppCode: saveFlowAppCode(state),
    uploadRunLoading: uploadRunLoading(state),
    uploadRunForm: uploadRunForm(state),
    upDateFlowElementConfig: upDateFlowElementConfig(state),
    upDateFlowElementIntentNode: upDateFlowElementIntentNode(state),
    deleteFlowIntent: deleteFlowIntent(state),
    addFlowIntent: addFlowIntent(state),
    upDateFlowData: upDateFlowData(state),
    deleteNodeLine: deleteNodeLine(state),
    uploadClick: uploadClick(state),
    upDataActiveId: upDataActiveId(state),
    deleteLine: deleteLine(state),
    upDataFLowName: upDataFLowName(state),
    upDataAddLine: upDataAddLine(state),
    getApplyList: getApplyList(state),
    getNoticeNameList: getNoticeNameList(state),
    updataRunStaus: updataRunStaus(state),
    upDataFLowConfig: upDataFLowConfig(state),
    openNewBase: openNewBase(state),
    updateUserCompanyName: updateUserCompanyName(state),
    upDataChat: upDataChat(state),
    upPhoneChat: upPhoneChat(state),
    getSessionIdChannelid: getSessionIdChannelid(state),
    chatDataUp: chatDataUp(state),
    chatDataChatO: chatDataChatO(state),
    updateNowSelectPlugin: updateNowSelectPlugin(state),
    updateNowSelectModel: updateNowSelectModel(state),
    updataHomeMenuList: updataHomeMenuList(state),
    upDateClickChat: upDateClickChat(state),
    upDataOpenFlowHis: upDataOpenFlowHis(state),
    upDataFlowDetail: upDataFlowDetail(state),
    upDataActiveFlow: upDataActiveFlow(state),
    updataQuestion: updataQuestion(state),
    updataWorkFlowPlugin: updataWorkFlowPlugin(state),
    updataSavetime: updataSavetime(state),
    saveFlowAciton: saveFlowAciton(state)
  };
}
