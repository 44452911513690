import { readonly } from 'vue'
import { createAction } from './action'
import { createState } from './state'

const state = createState()
const action = createAction(state)

export const createStore = () => {
  const store = {
    // 设置为readonly是因为我们只能通过action去修改state
    state: readonly(state),
    action: readonly(action)
  }
  return store
}
