// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";

/** 引入柱状图 + 折线图 + 饼图，图表后缀都为 Chart，一般常用的就这三个，如果还需要其他的，就自行添加  */
import { BarChart, LineChart, PieChart,GraphChart,HeatmapChart,EffectScatterChart,ScatterChart  } from "echarts/charts";

// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    ToolboxComponent,
    LegendComponent,
    GraphicComponent,
    VisualMapComponent,
    CalendarComponent,
} from "echarts/components";

// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";

// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    ToolboxComponent,
    LegendComponent,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    BarChart,
    LineChart,
    PieChart,
    GraphicComponent,
    VisualMapComponent,
    CalendarComponent,
    GraphChart,
    HeatmapChart,
    EffectScatterChart,
    ScatterChart 
]);

// 导出
export default echarts;
interface Item {
    [key: string]: any;
    code: string;
}

export function removeDuplicates(arr1: string[], arr2: Item[]): string[] {
    return arr1.filter(item1 => !arr2.some(item2 => item1 === item2.code));
}

export function mergeArrays(arr1: string[], arr2: Item[]): string[] {
    arr2.forEach(item2 => {
        if (!arr1.includes(item2.code)) {
            arr1.push(item2.code);
        }
    });
    return arr1;
}

